import * as React from 'react';

import { App } from "./App.tsx";

const AppFunctionWrapper = () => {
    return (
        <App />
    );
}

export {AppFunctionWrapper}