import Eichenlaub from "./Eichenlaub.png";
import KomootIcon from "./komoot-icon.png"
import MastodonIcon from "./Mastodon.png"
import PlaystoreIcon from "./playstore-icon.png"
import WappenOrt from "./Wappen_Ort.png";
import OWKLogoOGOfl from "./Ort Wappen Logo 1.png";
import OrtLogo from "./Ort Wappen Logo.png";

const Images = {
    Eichenlaub,
    KomootIcon,
    MastodonIcon,
    PlaystoreIcon,
    WappenOrt,
    OWKLogoOGOfl,
    OrtLogo
}

export default Images;