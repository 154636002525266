import { Typography } from '@mui/material';
import * as React from 'react';

import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import MediaQuery from "react-responsive";


import { fbib } from "./../Funktionsbibliothek.tsx";

// coordinates={item1.koordinaten} description={item1.beschreibung} zoom={item1.zoom} crlf={item.crlf}

const Karte = (props) => {

    let lineBreak;
    if (props.crlf) {
        lineBreak = (<br />);
    }

    return (
        <div
            style={{
                width: "100%"
            }}
        >
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <MapContainer center={props.coordinates} zoom={props.zoom} scrollWheelZoom={false}
                    style={{
                        height: 500,
                        width: "80%",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                    className="map"
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={props.coordinates} >
                        <Tooltip
                            permanent
                            direction="top"
                            offset={[-16, -15]}
                        >
                            <Typography>{props.description}</Typography>
                        </Tooltip>
                    </Marker>
                </MapContainer>
            </MediaQuery>
            <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <MapContainer center={props.coordinates} zoom={props.zoom} scrollWheelZoom={false} tap={false} dragging={false}
                    style={{
                        height: 500,
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                    className="map"
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={props.coordinates} >
                        <Tooltip
                            permanent
                            direction="top"
                            offset={[-16, -15]}
                        >
                            <Typography>{props.description}</Typography>
                        </Tooltip>
                    </Marker>
                </MapContainer>
            </MediaQuery>
            {lineBreak}
            {lineBreak}

        </div>
    );
}

export { Karte };