import * as React from 'react';

import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';

import MediaQuery from "react-responsive";

import { FussLinks } from "./FussLinks.tsx";
import { FussMitte } from './FussMitte.tsx';
import { FussRechts } from "./FussRechts.tsx";

import { fbib } from "./Funktionsbibliothek.tsx";
import { Copyright } from './Copyright.tsx';

const Fuss = () => {
    return (
        <div
            style={{
                background: "lightgrey",
                paddingTop: "20px"
            }}
        >
            <div
                style={{
                    alignItems: "center",
                    marginTop: "auto",
                }}
            >
                <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                    <Grid container wrap="nowrap" maxWidth={fbib.MAX_WIDTH_SCREEN}
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <Box display="flex" justifyContent="flex-end">
                            <FussLinks />
                        </Box>
                        <Box style={{ 
                                position: "absolute",
                                left: "50%",
                                transform: "translate(-50%, 0)"
                            }}>
                            <FussMitte />
                        </Box>
                        <Box display="flex" justifyContent="flex-start">
                            <FussRechts />
                        </Box>
                    </Grid>
                </MediaQuery>
                <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                    <div />
                    <FussMitte />
                    <div />
                </MediaQuery>
            </div>
            <Copyright />
        </div>
    );
};
export { Fuss };