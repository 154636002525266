import * as React from 'react';
import Carousel from 'react-material-ui-carousel'
import Tooltip from '@mui/material/Tooltip';

import MediaQuery from "react-responsive";
import uniqid from 'uniqid'

import { fbib } from "./Funktionsbibliothek.tsx";

import { Bannerbilder, BannerbildType, BannerbilderType } from './img/Bannerbilder/Index.tsx';


const randomizedImages: BannerbilderType = Bannerbilder.sort(() => Math.random() - 0.5);
let imagesCarousel1: BannerbilderType = [];
let imagesCarousel2: BannerbilderType = [];
let imagesCarousel3: BannerbilderType = [];

let i: number = 0;

randomizedImages.forEach(value => {
    switch (i) {
        case 0:
            imagesCarousel1.push(value);
            i = i + 1;
            break;
        case 1:
            imagesCarousel2.push(value);
            i = i + 1;
            break;
        case 2:
            imagesCarousel3.push(value);
            i = 0;
            break;
        default:
            break;
    }
});

const Banner = () => {

    return (
        <div>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_LANDSCAPE}>
                <div
                    style={{
                        height: "350px",
                        minHeight: "350px",
                        maxHeight: "350px",
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: "20px",
                        maxWidth: fbib.MAX_WIDTH_SCREEN,
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                >
                    <div
                        style={{
                            width: "33%"
                        }}
                    >
                        <Carousel
                            animation="fade"
                            //disableScrollLock={true}
                            //navButtonsAlwaysInvisible={true}
                            interval={6000}
                        >
                            {
                                imagesCarousel1.map((value: BannerbildType) => {
                                    return (
                                        <Tooltip title={value[1]} key={uniqid()}>
                                            <img
                                                style={{
                                                    height: "285px",
                                                    width: "auto",
                                                    borderRadius: 10
                                                }}
                                                src={value[0]}
                                                alt=""
                                                key={uniqid()}
                                            />
                                        </Tooltip>
                                    );
                                })
                            }
                        </Carousel>
                    </div>
                    <div
                        style={{
                            width: "33%"
                        }}
                    >
                        <Carousel
                            animation="fade"
                            //disableScrollLock={true}
                            //navButtonsAlwaysInvisible={true}
                            interval={6000}
                        >
                            {
                                imagesCarousel2.map((value: BannerbildType) => {
                                    return (
                                        <Tooltip title={value[1]} key={uniqid()}>
                                            <img
                                                style={{
                                                    height: "285px",
                                                    width: "auto",
                                                    borderRadius: 10
                                                }}
                                                src={value[0]}
                                                alt=""
                                                key={uniqid()}
                                            />
                                        </Tooltip>
                                    );
                                })
                            }
                        </Carousel>
                    </div>
                    <div
                        style={{
                            width: "33%"
                        }}
                    >
                        <Carousel
                            animation="fade"
                            //disableScrollLock={true}
                            //navButtonsAlwaysInvisible={true}
                            interval={6000}
                        >
                            {
                                imagesCarousel3.map((value: BannerbildType) => {
                                    return (
                                        <Tooltip title={value[1]} key={uniqid()}>
                                            <img
                                                style={{
                                                    height: "285px",
                                                    width: "auto",
                                                    borderRadius: 10
                                                }}
                                                src={value[0]}
                                                alt=""
                                                key={uniqid()}
                                            />
                                        </Tooltip>
                                    );
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT} maxWidth={fbib.WIDTH_SCREEN_LANDSCAPE}>
                <div
                    style={{
                        width: "100%",
                        marginTop: "20px"
                    }}
                >
                    <Carousel
                        animation="fade"
                        //disableScrollLock={true}
                        //navButtonsAlwaysInvisible={true}
                        interval={6000}
                    >
                        {
                            randomizedImages.map((value: BannerbildType) => {
                                return (
                                    <Tooltip title={value[1]} key={uniqid()}>
                                        <img
                                            style={{
                                                height: "285px",
                                                width: "auto",
                                                borderRadius: 10
                                            }}
                                            src={value[0]}
                                            alt=""
                                            key={uniqid()}
                                        />
                                    </Tooltip>
                                );
                            })
                        }
                    </Carousel>
                </div>
            </MediaQuery>
        </div>
    );
}
export { Banner };