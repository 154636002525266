import { createBrowserHistory } from "history";
import { createContext } from "react";
import { create } from "zustand";

import { fbib } from "../Funktionsbibliothek.tsx";

export const globalStateContext = createContext({
    history: "",
    pageState: "",
    setPageState: () => { },
    setPageStatePopState: () => { },
    Kapitel: "",
    setKapitel: () => { },
    MenueEintraege: "",
    setMenueEintraege: () => { },
    Inhaltsverszeichnis: "",
    data: "",
    setData: () => { }
});

export const globalState = create((set, get) => ({
    history: createBrowserHistory(),
    pageState: "",
    setPageState: (input) => {
        if (window.visualViewport.width >= fbib.WIDTH_SCREEN_LANDSCAPE) {
            if (window.scrollY > 360) {
                window.scrollTo(0, 360);
            }
        }
        else {
            if (window.scrollY > 320) {
                window.scrollTo(0, 320);
            }
        }
        set((state) => ({ pageState: input }));
        get().history.push(input);
        get().setKapitel(input.split('/')[1]); //Index 0 ist leerer String
        get().Inhaltsverszeichnis.map((item, index) => {
            if (item[0] === get().Kapitel) {
                get().setMenueEintraege(item[1]);
            }
            return "";
        });
        fetch("/json" + get().pageState)
            .then(response => response.json())
            .then(data => get().setData(data));
    },
    setPageStatePopState: (input) => {
        set((state) => ({ pageState: input }));
        get().setKapitel(input.split('/')[1]); //Index 0 ist leerer String
        get().Inhaltsverszeichnis.map((item, index) => {
            if (item[0] === get().Kapitel) {
                get().setMenueEintraege(item[1]);
            }
            return "";
        });
        fetch("/json" + get().pageState)
            .then(response => response.json())
            .then(data => get().setData(data));
    },
    Kapitel: "",
    setKapitel: (input) => set((state) => ({ Kapitel: input })),
    MenueEintraege: "",
    setMenueEintraege: (input) => set((state) => ({ MenueEintraege: input })),
    Inhaltsverszeichnis: [
        ["Startseite", ["Willkommen", "Vereinsziele", "Vorstand", "Ansprechpartner", "Rastplätze"]],
        ["Wanderungen", ["Wanderplan", "Wanderwoche", "Gemarkungswanderung", "Wanderapp"]],
        ["Mitglieder", ["Mitgliedschaft", "Datenschutzerklärung"]],
        ["Termine", ["Hauptversammlung", "Wanderehrung", "Sommerfest", "Wochenspiegel"]],
        ["Unsere Ortschaft", []],
        ["Impressum", ["Impressum"]],
        ["Downloads", ["Downloads"]],
        ["Archiv", ["Übersicht"]]
    ],
    data: "",
    setData: (input) => set((state) => ({ data: input }))
}));

export const GlobalStateProvider = ({ children }) => {
    const { history, pageState, setPageState, setPageStatePopState, Kapitel, setKapitel, MenueEintraege, setMenueEintraege, Inhaltsverszeichnis, data, setData } = globalState();

    return (
        <globalStateContext.Provider
            value={{
                history,
                pageState,
                setPageState,
                setPageStatePopState,
                Kapitel,
                setKapitel,
                MenueEintraege,
                setMenueEintraege,
                Inhaltsverszeichnis,
                data,
                setData
            }}
        >
            {children}
        </globalStateContext.Provider>
    );
};