import * as React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Typography from '@mui/material/Typography';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import uniqid from 'uniqid';

import { fbib } from "./Funktionsbibliothek.tsx";
import { globalState } from './globalState/globalState';

import { StyleSheet } from './interfaces/Stylesheet';


const Kapitelinhalt = () => {

    let eintraege: JSX.Element;
    try {
        eintraege = globalState.getState().MenueEintraege.map((item, index) => {
            let arrow: JSX.Element;
            let a = "";
            try {
                a = fbib.Sonderzeichenreferenz.URLzuUmlaut(window.location.pathname.split('/')[2]);
            }
            catch { }
            if (item.replace(" ", "") === a || a === "") {
                arrow = (<KeyboardArrowRightIcon />);
            }
            else {
                arrow = (<KeyboardArrowLeftIcon />);
            }

            return (
                <ListItem
                    style={styles.listItem}
                    key={uniqid()}
                >
                    < ListItemIcon
                        style={styles.listItemIcon}
                    >
                        {arrow}
                    </ListItemIcon >
                    <ListItemText
                        style={{

                        }}
                        primary={
                            <Link
                                component="button"
                                onClick={() => {
                                    if (globalState.getState().Kapitel === "Impressum" || globalState.getState().Kapitel === "Downloads") {
                                        globalState.getState().setPageState("/" + globalState.getState().Kapitel)
                                    }
                                    else {
                                        globalState.getState().setPageState("/" + globalState.getState().Kapitel + "/" + item.replace(" ", ""))
                                    }
                                    //console.log(globalState.getState());
                                }}
                                underline="hover"
                                style={styles.linkButton}
                            >
                                <Typography>
                                    {item}
                                </Typography>
                            </Link>
                        } />
                </ListItem >
            )
        });
    }
    catch {
        eintraege = <div></div>;
    }

    return (
        <Card
            style={styles.card}
        >
            <CardHeader
                title={
                    <Typography
                        component="div"
                        variant="h5"
                        textAlign="left"
                    >
                        Kapitelinhalt: {globalState.getState().Kapitel}
                        <hr />
                    </Typography>
                }
                subheader={
                    <List
                        style={styles.cardSubheader}
                    >
                        {eintraege}
                    </List>
                }
            />
        </Card>
    );
}

const styles: StyleSheet = {
    card: {
        border: "none",
        boxShadow: "none",
        background: "transparent"
    },
    cardSubheader: {
        backgroundColor: "transparent",
        marginTop: "-20px"
    },
    listItem: {
        padding: "0",
        textAlign: "left"
    },
    listItemIcon: {
        minWidth: "auto"
    },
    linkButton: {
        color: "black",
        margin: "0",
        padding: "0"
    }
}

export { Kapitelinhalt };