import * as React from 'react';

import { Typography } from '@mui/material';

const Copyright = () => {
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                verticalAlign: "center",
                paddingTop: "50px",
                paddingBottom: "50px"
            }}
        >
            <Typography>© {new Date().getUTCFullYear()} Odenwaldklub Ortsgruppe Oberflockenbach</Typography>
        </div>
    );
}

export { Copyright };