import * as React from 'react';

import Grid from '@mui/material/Grid';

import MediaQuery from "react-responsive";

import { fbib } from "./Funktionsbibliothek.tsx";

import { HauptteilLinks } from './HauptteilLinks.tsx';
import { HauptteilMitte } from './HauptteilMitte.tsx';
import { HauptteilRechts } from "./HauptteilRechts.tsx"

const Hauptteil = () => {

    return (
        <Grid container wrap="nowrap" maxWidth={fbib.MAX_WIDTH_SCREEN}
            style={{
                marginLeft: "auto",
                marginRight: "auto"
            }}
        >
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_LANDSCAPE}>
                <Grid item xs></Grid>
                <Grid item xs minWidth={350}>
                    <HauptteilLinks />
                </Grid>
                <Grid item xs={0.3}></Grid>
            </MediaQuery >
            <MediaQuery maxWidth={fbib.WIDTH_SCREEN_LANDSCAPE}>
                <Grid item xs={12}
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px"
                    }}
                >
                    <HauptteilMitte minHeight={"calc(100vh - 571px)"} />
                </Grid>
            </MediaQuery>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_LANDSCAPE}>
                <Grid item xs={6}
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px"
                    }}
                >
                    <HauptteilMitte minHeight={"calc(100vh - 621px)"} />
                </Grid>
            </MediaQuery>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_LANDSCAPE}>
                <Grid item xs>
                    <HauptteilRechts />
                </Grid>
            </MediaQuery>
        </Grid >
    );
};

export { Hauptteil };