import * as React from "react";
import {
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
  } from "@fluentui/react-components";
import { Typography } from '@mui/material';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { globalState } from './globalState/globalState';

import uniqid from 'uniqid';
  
interface NavigationDropdownProps {
    Kapitel: any,
    MenueEintraege?: any
}  

const NavigationDropdownNeu = (props: NavigationDropdownProps) => {
    const [open, setOpen] = React.useState(false);
    const [icon, setIcon] = React.useState(<ArrowDropDownIcon />)
    const [buttonStyle, setButtonStyle] = React.useState({
            height: "63px",
            color: "white",
            background: "transparent"
        })


    const handleOpen = (event) => {
        setOpen(true)
        setIcon(<ArrowDropUpIcon style={{color:"white"}} />)
        setButtonStyle({
            height: "63px",
            color: "white",
            background: "rgba(0,0,0, 0.05)"
        })
    }

    const handleClose = (event) => {
        setOpen(false)
        setIcon(<ArrowDropDownIcon />)
        setButtonStyle({
            height: "63px",
            color: "white",
            background: "transparent"
        })
    }

    const handleClick = unterseite => event => {
        globalState.getState().setPageState("/" + props.Kapitel + "/" + unterseite.replace(" ", ""));

        setOpen(false)
        setIcon(<ArrowDropDownIcon />)
        setButtonStyle({
            height: "63px",
            color: "white",
            background: "transparent"
        })
    }

    return (
        <div
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
            style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center"
            }}
        >
        <Menu
            open={open}
        >
            <MenuTrigger>
                    <Button 
                        appearance="transparent"
                        size = "large"
                        icon = {icon}
                        iconPosition = "after"
                        style={buttonStyle}
                    >
                            <Typography
                                fontSize={20}
                            >
                                {props.Kapitel}
                            </Typography>
                    </Button>
            </MenuTrigger>
        
            <MenuPopover>
                <MenuList>
                    {props.MenueEintraege.map((item, index) => {
                        return (
                        <MenuItem
                            onClick={handleClick(item)}
                            key={uniqid()}
                        >
                            <Typography
                                fontSize={16}
                            >
                                {item}
                            </Typography>
                        </MenuItem>);
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    </div>
  );
}

export { NavigationDropdownNeu };