import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CircleIcon from '@mui/icons-material/Circle';

import parse from 'html-react-parser';

import MediaQuery from "react-responsive";

import uniqid from 'uniqid';

import { StyleSheet } from '../interfaces/Stylesheet';
import { fbib } from "./../Funktionsbibliothek.tsx";

interface ListItemProps {
    text: string
}

interface ListeProps {
    listItems: ListItemProps[]
}

const Liste = (props: ListeProps) => {

    return (
        <div>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <List
                    style={styles.computerList}
                    key={uniqid()}
                >
                    {
                        props.listItems.map((value) => {
                            return (
                                <ListItem
                                    style={{
                                        padding: "0"
                                    }}
                                    key={uniqid()}
                                >
                                    <ListItemIcon
                                        style={styles.computerListItemIcon}
                                    >
                                        <CircleIcon
                                            style={styles.computerCircleIcon}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={styles.computerListItemText}
                                        primary={parse(value.text)}
                                    />
                                </ListItem>
                            );
                        })
                    }
                </List>
            </MediaQuery>
            <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <List
                    style={styles.phoneList}
                    key={uniqid()}
                >
                    {
                        props.listItems.map((value) => {
                            return (
                                <ListItem
                                    style={styles.phoneListItem}
                                    key={uniqid()}
                                >
                                    <ListItemIcon
                                        style={styles.phoneListItemIcon}
                                    >
                                        <CircleIcon
                                            style={styles.phoneCircleIcon}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={styles.phoneListItemText}
                                        primary={parse(value.text)}
                                    />
                                </ListItem>
                            );
                        })
                    }
                </List>
            </MediaQuery>
        </div>
    );
};

const styles: StyleSheet = {
    computerList: {
        backgroundColor: "transparent",
        paddingLeft: "10px",
        maxWidth: "80%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    computerListItemIcon: {
        minWidth: "20px",
    },
    computerCircleIcon: {
        color: "black",
        width: "10px",
        height: "auto"
    },
    computerListItemText: {
        fontSize: "15pt"
    },
    phoneList: {
        backgroundColor: "transparent",
        paddingLeft: "10px",
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    phoneListItem: {
        padding: "0"
    },
    phoneListItemIcon: {
        minWidth: "20px"
    },
    phoneCircleIcon: {
        color: "black",
        width: "10px",
        height: "auto"
    },
    phoneListItemText: {
        fontSize: "13pt",
        hyphens: "auto"
    }
}

export { Liste };