import * as React from 'react';

import MediaQuery from "react-responsive";

import { StyleSheet } from '../interfaces/Stylesheet';

import { fbib } from "./../Funktionsbibliothek.tsx";

import uniqid from 'uniqid';

interface WanderplanBreitProps {
    crlf: boolean
}

const WanderplanBreit = (props: WanderplanBreitProps) => {

    let lineBreak: JSX.Element;
    if (props.crlf) {
        lineBreak = (<br />);
    }
    else {
        lineBreak = (<div />)
    }

    return (
        <div>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <iframe
                    title={uniqid()}
                    key={uniqid()}
                    src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%230B8043&amp;ctz=Europe%2FBerlin&amp;src=ZG1hcjlsc3Y4cDNqZW9oajEwOHE1Nm42MWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=ZGUuZ2VybWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%233F51B5&amp;color=%230B8043&amp;showTitle=0"
                    style={styles.wanderplan}
                    width="100%"
                    height="600"
                />
                {lineBreak}
            </MediaQuery>
            <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <iframe
                    title={uniqid()}
                    key={uniqid()}
                    src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=2&amp;bgcolor=%230B8043&amp;ctz=Europe%2FBerlin&amp;src=ZG1hcjlsc3Y4cDNqZW9oajEwOHE1Nm42MWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=ZGUuZ2VybWFuI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%233F51B5&amp;color=%230B8043&amp;showTitle=0"
                    style={styles.wanderplan}
                    width="100%"
                    height="500px"
                />
                {lineBreak}
            </MediaQuery>
        </div>
    );
}

const styles: StyleSheet = {
    wanderplan: {
        border: "solid 1px #777"
    }
};

export { WanderplanBreit };