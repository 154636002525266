import React from 'react';
//import ReactDOM from 'react-dom';

import { createRoot } from 'react-dom/client';

import {
  FluentProvider,
  webLightTheme,
} from "@fluentui/react-components";

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { AppFunctionWrapper } from './AppFunctionWrapper.tsx';
import { GlobalStateProvider } from './globalState/globalState';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <FluentProvider theme={webLightTheme}>
        <GlobalStateProvider>
          <AppFunctionWrapper />
        </GlobalStateProvider>
    </FluentProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
