import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import parse from 'html-react-parser';

import MediaQuery from "react-responsive";

import uniqid from 'uniqid';

import { fbib } from "../Funktionsbibliothek.tsx";
import { StyleSheet } from '../interfaces/Stylesheet';

interface NummerierteListeEintrag {
    text: string
}

interface NummerierteListeProps {
    listItems: NummerierteListeEintrag[]
}

const NummerierteListe = (props: NummerierteListeProps) => {

    return (
        <div>
            <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <List
                    style={styles.landscapeList}
                    key={uniqid()}
                >
                    {
                        props.listItems.map((item, index) => {
                            return (
                                <ListItem
                                    style={{
                                        padding: "0"
                                    }}
                                    key={uniqid()}
                                >
                                    <ListItemIcon
                                        style={{
                                            minWidth: "20px",
                                        }}
                                    >
                                        <ListItemText
                                            primaryTypographyProps={styles.landscapeListItemText}
                                            primary={(index + 1) + "."}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={styles.landscapeListItemText2}
                                        primary={parse(item.text)}
                                    />
                                </ListItem>
                            );
                        })
                    }
                </List>
            </MediaQuery>
            <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
                <List
                    style={styles.phonelandscapeList}
                    key={uniqid()}
                >
                    {
                        props.listItems.map((item, index) => {
                            return (
                                <ListItem
                                    style={{
                                        padding: "0"
                                    }}
                                    key={uniqid()}
                                >
                                    <ListItemIcon
                                        style={{
                                            minWidth: "20px"
                                        }}
                                    >
                                        <ListItemText
                                            primaryTypographyProps={styles.phoneListItemText}
                                            primary={(index + 1) + "."}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={styles.phoneListItemText2}
                                        primary={parse(item.text)}
                                    />
                                </ListItem>
                            );
                        })
                    }
                </List>
            </MediaQuery>
        </div>
    );
}

const styles: StyleSheet = {
    landscapeList: {
        backgroundColor: "transparent",
        paddingLeft: "10px",
        maxWidth: "70%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    landscapeListItemText: {
        fontSize: "15pt",
        textAlign: "right",
        marginRight: "10px",
        minWidth: "26px"
    },
    landscapeListItemText2: {
        fontSize: "15pt"
    },
    phonelandscapeList: {
        backgroundColor: "transparent",
        paddingLeft: "10px",
        maxWidth: "100%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    phoneListItemText: {
        fontSize: "13pt",
        textAlign: "right",
        marginRight: "10px",
        minWidth: "26px"
    },
    phoneListItemText2: {
        fontSize: "13pt",
        hyphens: "auto"
    }
}

export { NummerierteListe };
