import * as React from 'react';

import { CookieMeldung } from './CookieMeldung.tsx';
import { Fuss } from "./Fuss.tsx"
import { Hauptteil } from './Hauptteil.tsx';
import { Kopf } from './Kopf.tsx';
import { Navigation } from './Navigation.tsx';
import NavigationDrawer from "./NavigationDrawer.js"

import MediaQuery from "react-responsive";

import { fbib } from "./Funktionsbibliothek.tsx";
import { globalState, globalStateContext } from './globalState/globalState';


class App extends React.Component {

  componentDidMount() {
    globalState.getState().setPageState(window.location.pathname === "/" ? "/Startseite/Willkommen" : window.location.pathname);

    //console.log(globalState.getState());

    fetch("/json" + globalState.getState().pageState)
      .then(response => response.json())
      .then(data => this.setState({ content: data }));

    window.onpopstate = () => {
      globalState.getState().setPageStatePopState(window.location.pathname === "/" ? "/Startseite/Willkommen" : window.location.pathname);
    }
  }

  render() {
    return (
      <div
        style={{
          background : "#F4F5F7"
        }}
      >
        <CookieMeldung />
        <MediaQuery minWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
          <Navigation />
        </MediaQuery>
        <Kopf />
        <MediaQuery maxWidth={fbib.WIDTH_SCREEN_PORTRAIT}>
          <NavigationDrawer />
        </MediaQuery>
        <Hauptteil />
        <Fuss />
      </div>
    );
  }
}

App.contextType = globalStateContext;

export { App };
