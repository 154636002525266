import * as React from 'react';

import { Absatz } from './Absatz.tsx';
import Akkordeon from './Akkordeon';
import { Bild } from './Bild.tsx';
import { Bildergallerie } from './Bildergallerie.tsx';
import { Bilderkarten } from './Bilderkarten.tsx';
import { Facebook } from './Facebook.tsx';
import { HorizontaleLinie } from './HorizontaleLinie.tsx';
import { Karte } from './Karte.tsx';
import { Komoot } from './Komoot.tsx';
import { Liste } from "./Liste.tsx"
import { NummerierteListe } from './NummerierteListe.tsx';
import { WanderplanBreit } from "./WanderplanBreit.tsx"

import uniqid from 'uniqid';

interface ContentProps {
    renderContent: any
}

const Content = (props: ContentProps) => {
    let content;
    if (props.renderContent !== undefined) {
        content = props.renderContent.map((item, index) => {
            switch (item.type) {
                case "Absatz":
                    return (<Absatz key={uniqid()} text={item.text} crlf={item.crlf} />);

                case "Akkordeon":
                    return (<Akkordeon key={uniqid()} sections={item.sections} />);

                case "Bild":
                    return (<Bild key={uniqid()} src={item.src} desc={item.desc} orientation={item.orientation} />);

                case "Bildergallerie":
                    return (<Bildergallerie key={uniqid()} images={item.images} />)

                case "Bilderkarten":
                    return (<Bilderkarten key={uniqid()} cards={item.cards} />);

                case "Facebook":
                    return (<Facebook key={uniqid()} src={item.src} href={item.href} />);

                case "HorizontaleLinie":
                    return (<HorizontaleLinie key={uniqid()} />)

                case "Karte":
                    return (<Karte coordinates={item.koordinaten} description={item.beschreibung} zoom={item.zoom} crlf={item.crlf} key={uniqid()} />);

                case "Komoot":
                    return (<Komoot key={uniqid()} src={item.src} />);

                case "Liste":
                    return (<Liste key={uniqid()} listItems={item.listItems} />);

                case "NummerierteListe":
                    return (<NummerierteListe key={uniqid()} listItems={item.listItems} />);

                case "Wanderplan":
                    return (<WanderplanBreit key={uniqid()} />);

                default:
                    return (<div key={uniqid()} />);
            }
        })
    } else {
        content = <div></div>
    }

    return (
        content
    )
};


export { Content };