import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';


import Images from './img/Index.js'

import { NavigationDropdownNeu } from './NavigationDropdownNeu.tsx';

import { globalState } from './globalState/globalState';

import uniqid from 'uniqid';

const elemente = globalState.getState().Inhaltsverszeichnis.map((item, index) => {
    if (item[0] !== "Downloads" && item[0] !== "Impressum") {
        return (
            <NavigationDropdownNeu
                Kapitel={item[0]}
                MenueEintraege={item[1]}
                key={uniqid()}
            />
        );
    } else {
        return null;
    }
});

const wirSindAuchAuf = 
    <div>
        <List
            style={{
                backgroundColor: "transparent",
                textWrap: "nowrap",
                display: "flex",
                marginTop: "5px"
            }}
        >
            <ListItem
                style={{
                    padding: "0",
                    textAlign: "center"
                }}
            >
            <ListItemText
                primary={<Typography>Wir sind auch auf:</Typography>}
                style={{
                    marginRight: "10px"
                }}
            />
            </ListItem>
                <ListItem
                style={{
                    padding: "0",
                    textAlign: "right"
                }}
            >
            <ListItemText
                primary={
                    <div
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <Tooltip title="Facebook">
                            <IconButton
                                style={{
                                    padding: "0px",
                                    marginTop: "1px",
                                    marginBottom: "1px"
                                }}
                                onClick={() => {
                                    let url = "https://www.facebook.com/OWKOfl";
                                    window.open(url, '_blank');
                                }
                                }
                            >
                                <FacebookIcon
                                    style={{
                                        color: "black",
                                        width: "25px",
                                        height: "auto"
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Komoot">
                            <IconButton
                                style={{
                                    padding: "2px",
                                }}
                                onClick={() => {
                                    let url = "https://www.komoot.de/user/1654017259531";
                                    window.open(url, '_blank');
                                }
                                }
                            >
                                <img
                                    src={Images.KomootIcon}
                                    style={{
                                        width: "20px",
                                        height: "auto"
                                    }}
                                    alt=""
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Google Playstore">
                            <IconButton
                                style={{
                                    padding: "2px",
                                }}
                                onClick={() => {
                                    let url = "https://play.google.com/store/apps/details?id=com.owk_wanderapp.app";
                                    window.open(url, '_blank');
                                }
                                }
                            >
                                <img
                                    src={Images.PlaystoreIcon}
                                    style={{
                                        width: "20px",
                                        height: "auto"
                                    }}
                                    alt=""
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Mastodon">
                            <IconButton
                                style={{
                                    padding: "2px",
                                }}
                                onClick={() => {
                                    let url = "https://rheinneckar.social/@OWKOfl";
                                    window.open(url, '_blank');
                                }
                                }
                            >
                                <img
                                    src={Images.MastodonIcon}
                                    style={{
                                        width: "20px",
                                        height: "auto"
                                    }}
                                    alt=""
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="X (ehem. Twitter)">
                            <IconButton
                                style={{
                                    padding: "2px",
                                }}
                                onClick={() => {
                                    let url = "https://twitter.com/OWKOfl";
                                    window.open(url, '_blank');
                                }
                                }
                            >
                                <XIcon
                                    style={{
                                        color: "black",
                                        width: "20px",
                                        height: "auto"
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                }   
            />
            </ListItem>
        </List>
    </div>
;


const Navigation = () => {
    

    return (
        <AppBar
            style={{
                position: 'sticky',
                background: 'green',
                alignItems: 'center'
            }}
        >
            <Toolbar
                style={{
                    width: "1980px",
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <Tooltip title="Eichenlaub, Wappen des OWK">
                <div
                    style={{
                        display: "flex"
                    }}
                >
                    <img
                        src={Images.OWKLogoOGOfl}
                        alt="Wappen Ort"
                        style={{
                            height: '60px',
                        }}
                    />
                    <div
                        style={{
                            marginLeft: "10px",
                            display: "flex",
                            flexDirection : "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Typography
                            fontSize={22}
                        >
                            Odenwaldklub
                        </Typography>
                        <Typography>Ortsgruppe Oberflockenbach</Typography>
                    </div>
                </div>
                </Tooltip>
                <div
                    style={{
                        display:"flex",
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%, 0)"
                    }}
                >
                    {elemente}
                </div>
                <div
                    style={{
                        display: "flex"
                    }}
                >
                   {wirSindAuchAuf}
                    <Tooltip title="Wappen von Oberflockenbach">
                        <img
                            src={Images.OrtLogo}
                            alt="Wappen "
                            style={{
                                height: '60px',
                                margin: "0"
                            }}
                        />
                    </Tooltip>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export { Navigation };